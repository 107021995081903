/** @jsx jsx */
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { StaticImage } from "gatsby-plugin-image";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";
import banner from "../../static/imgs/yoga-banner.jpg";

const pageContent =
  '<gatsby_donation amount="0" btntext="Donate here" donationid="32" ' +
  'projectid="" btnbg="#095788" colortext="black" colorprimary="#095788"' +
  'colorsecondary="#095788" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="Heartfulness Yoga Donation"/>';

const PageContent = React.memo(PageContentNonMemoized);
const YogaDonation = () => {
  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("heartfulness-yoga-donation", event),
    []
  );

  const renderContentHeader = () => (
    <>
      <Box
        sx={{
          minHeight: "560px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${banner})`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ paddingBottom: "15px" }}>
          <h1
            sx={{
              color: "White",
              marginTop: "450px",
              fontWeight: "600",
              lineHeight: "1.6",
            }}
          >
            HEARTFULNESS YOGA ACADEMY
          </h1>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent}
          />
        </Box>
      </Box>
    </>
  );

  return (
    <LayoutHome
      seoTitle="Donations - Heartfulness Yoga Donations"
      contentHeader={renderContentHeader()}
    >
      <Grid
        columns={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Box sx={{ marginTop: "30px" }}>
          <p
            sx={{
              textAlign: "center",
            }}
          >
            The Heartfulness Yoga Academy strives to offer quality Yoga classes
            and programs with an aim that Yoga enables an integrated lifestyle
            for all at large, irrespective of culture, knowledge, language, sex
            and age. Here are a few of our current programs.
          </p>
          <p
            sx={{
              textAlign: "center",
            }}
          >
            Visit Kanha Shanti Vanam to feel the transformative effect of Yoga,
          </p>
        </Box>
        <Grid columns={[1, 1, 3, null]}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              padding: "0px 13px 0px 13px",
            }}
          >
            <Grid
              sx={{
                border: "5px solid #fbc72c",
                textAlign: "center",
                alignItems: "center",
                padding: "0px 35px 0px 35px",
              }}
            >
              <Box
                sx={{
                  padding: "15px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  sx={{
                    backgroundColor: "#fbc72c",
                    height: "55px",
                    width: "150px",
                  }}
                >
                  <b>COMMUNITY CLASSES</b>
                </p>
              </Box>
              <Box>
                <StaticImage
                  src="../../static/imgs/group_yoga.jpg"
                  alt="Logo"
                  sx={{ height: "220px" }}
                />
              </Box>
              <Box sx={{ minHeight: "200px" }}>
                <p>
                  Free online and onsite daily Yoga classes with assorted focus
                  such as classes for kids, for women, for elders. A few are
                  even available online.
                </p>
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              padding: "0px 13px 0px 13px",
            }}
          >
            <Grid
              sx={{
                border: "5px solid #78b96e",
                textAlign: "center",
                alignItems: "center",
                padding: "0px 35px 0px 35px",
              }}
            >
              <Box
                sx={{
                  padding: "15px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  sx={{
                    backgroundColor: "#78b96e",
                    width: "150px",
                  }}
                >
                  <b>THERAPEUTIC YOGA</b>
                </p>
              </Box>
              <Box>
                <StaticImage
                  src="../../static/imgs/yoga-lesson.jpg"
                  alt="Logo"
                  sx={{ height: "220px" }}
                />
              </Box>
              <Box sx={{ minHeight: "200px" }}>
                <p>
                  Free workshops for hypertension, weight: management, diabetes,
                  mental wellbeing are offered on demand.
                </p>
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              padding: "0px 13px 0px 13px",
            }}
          >
            <Grid
              sx={{
                border: "5px solid #58bfb6",
                textAlign: "center",
                alignItems: "center",
                padding: "0px 35px 0px 35px",
              }}
            >
              <Box
                sx={{
                  padding: "15px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  sx={{
                    backgroundColor: "#58bfb6",
                    width: "150px",
                  }}
                >
                  <b>ONLINE WORKSHOPS</b>
                </p>
              </Box>
              <Box>
                <StaticImage
                  src="../../static/imgs/self_yoga.jpg"
                  alt="Logo"
                  sx={{ height: "220px" }}
                />
              </Box>
              <Box sx={{ minHeight: "200px" }}>
                <p>
                  For corporates and institutions: Yoga offered for employees
                  and students to promote a healthy stress-free and productive
                  lifestyle.
                </p>
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Box sx={{ paddingBottom: "15px" }}>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent}
          />
        </Box>
      </Grid>
      <Box
        sx={{
          color: "White",
          fontSize: "40px",
          textAlign: "center",
          background: "#56beb5",
          height: "150px",
        }}
      >
        <p sx={{ paddingTop: "25px" }}>
          There are many more free initiatives started by the Heartfulness Yoga
          Academy.
        </p>
        <p>
          Discover them and many more programs here
          <a
            href="https://heartfulness.org/yoga"
            target="_blank"
            sx={{ color: "#095788", marginLeft: "5px" }}
            rel="noreferrer"
          >
            Heartfulness Yoga.
          </a>
        </p>
      </Box>
    </LayoutHome>
  );
};

export default YogaDonation;
